import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import {
  ActivitySimpleStatusEnum,
  ServiceUserActivityDetailModel,
  ServiceUserActivityModel
} from 'features/activity.types';
import {
  ActivityStatus,
  ApiId,
  getActivitySimpleType,
  IApiResponse,
  mapApiErrors,
  ServiceUserProfile
} from 'millbrook-core';
import { getItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';

/* state */
interface ActivityState {
  details?: ServiceUserActivityDetailModel;
}

const initialState: ActivityState = {};

/* slice */
const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setActivityDetails(state, action: PayloadAction<ServiceUserActivityDetailModel | undefined>) {
      state.details = action.payload;
    }
  }
});

/* actions */
export const { setActivityDetails } = activitySlice.actions;

/* Thunks */

const mapActivityStatusToSimpleStatus = (activityStatus: ActivityStatus): ActivitySimpleStatusEnum => {
  let activitySimpleStatus = ActivitySimpleStatusEnum.Open;

  if (
    activityStatus === ActivityStatus.Completed ||
    activityStatus === ActivityStatus.Cancelled ||
    activityStatus === ActivityStatus.Declined ||
    activityStatus === ActivityStatus.Failed_CARESError ||
    activityStatus === ActivityStatus.Failed_SnapError ||
    activityStatus === ActivityStatus.Failed_StreamError
  ) {
    activitySimpleStatus = ActivitySimpleStatusEnum.Failed;
    switch (activityStatus) {
      case ActivityStatus.Completed:
        activitySimpleStatus = ActivitySimpleStatusEnum.Closed;
        break;
      case ActivityStatus.Cancelled:
        activitySimpleStatus = ActivitySimpleStatusEnum.Cancelled;
        break;
      case ActivityStatus.Declined:
        activitySimpleStatus = ActivitySimpleStatusEnum.Cancelled;
        break;
    }
  }

  return activitySimpleStatus;
};

export const fetchShubServiceUserBookings =
  (upcomingOnly?: boolean, hideOverlay: boolean = false): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, IApiResponse<ServiceUserActivityModel[]>>(ENDPOINTS.SERVICE_USER_BOOKINGS(upcomingOnly), '', {
      disableFullPageLoader: hideOverlay
    }).then(
      (response) => {
        const mappedBookings = (response.result || []).map((booking) => {
          const activitySimpleType = getActivitySimpleType(booking.activityReference);
          const activitySimpleStatus = mapActivityStatusToSimpleStatus(booking.activityStatus);

          return { ...booking, activitySimpleStatus, activitySimpleType };
        });

        return mappedBookings;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchShubActivityDetails =
  (activityId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, IApiResponse<ServiceUserActivityDetailModel>>(
      ENDPOINTS.SERVICE_USER_BOOKINGS(),
      activityId
    ).then(
      (response) => {
        const activityDetails = response.result ? { ...response.result, id: activityId } : undefined;

        if (activityDetails?.status) {
          activityDetails.activitySimpleStatus = mapActivityStatusToSimpleStatus(activityDetails?.status);
        }

        dispatch(setActivityDetails(activityDetails));
        return activityDetails;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/* selectors */
export const selectActivityDetails = (state: RootState) => state.activity.details;

export const selectActivityTypeName = (state: RootState) => {
  return getActivitySimpleType(state.activity.details?.activityReference);
};

export const selectActivityStatus = (state: RootState) => state.activity.details?.status;

export const selectActivityServiceUser = (state: RootState) => state.activity.details?.serviceUser;

export const selectActivityServiceUserId = (state: RootState) => state.activity.details?.serviceUserId;

export const selectActivityServiceUserAddress = (state: RootState) => state.activity.details?.serviceUserAddress;

export const selectActivityServiceUserProfile = createSelector(
  [selectActivityServiceUser, selectActivityServiceUserId, selectActivityServiceUserAddress],
  (serviceUser, id, serviceUserAddress) => {
    if (!serviceUser || !id || !serviceUserAddress) {
      return undefined;
    }

    const serviceUserDetails: ServiceUserProfile = {
      id,
      serviceUserReference: serviceUser.serviceUserReference,
      title: serviceUser.title,
      firstName: serviceUser.firstName,
      middleName: serviceUser.middleName,
      surname: serviceUser.surname,
      mobile: serviceUser.mobile,
      landline: serviceUser.landline,
      serviceUserAddress
    };

    return serviceUserDetails;
  }
);

// PIN USER
export const selectActivityPinUser = (state: RootState) => state.activity.details?.pinUser;

// BASKET
export const selectActivityBasket = (state: RootState) => state.activity.details?.basket;

export const selectActivityBasketItems = (state: RootState) => state.activity.details?.basket.basketItems || [];

/* reducers */
export default activitySlice.reducer;
