import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { IApiResponse, mapApiErrors } from 'millbrook-core';
import { RootState } from 'store/store';
import { ServiceUserExtendedDetailsModel } from 'pages/ShubUserDetailsPage/ShubUserDetailsPage';
import { getItem, postItems } from 'services/api.service';
import { AppThunk } from 'store/store';
import { EditTwoFactorFormType } from 'features/serviceUser/serviceUser.validation';

interface ServiceUserState {
  serviceUserDetails?: ServiceUserExtendedDetailsModel;
}

const initialState: ServiceUserState = {};

const serviceUserSlice = createSlice({
  name: 'serviceUser',
  initialState,
  reducers: {
    setServiceUser(state, action: PayloadAction<ServiceUserExtendedDetailsModel | undefined>) {
      state.serviceUserDetails = action.payload;
    },
    clearServiceUser(state) {
      return initialState;
    }
  }
});

export const { setServiceUser, clearServiceUser } = serviceUserSlice.actions;

// TODO: remove the id. This should be from the current logged in user and not passed from this code
export const fetchShubServiceUserDetails = (): AppThunk => async (dispatch) => {
  return getItem<string, IApiResponse<ServiceUserExtendedDetailsModel>>(ENDPOINTS.SERVICE_USER_DETAILS).then(
    (response) => {
      dispatch(setServiceUser(response.result));
      return response.result;
    },
    (response) => {
      const error = mapApiErrors(response);
      throw new Error(error);
    }
  );
};

export const availableTwoFactorMediums = (): AppThunk => async (dispatch) => {
  return getItem<void, IApiResponse<string[]>>(ENDPOINTS.TWO_FACTOR_MEDIUMS).then(
    (response) => {
      return response.result;
    },
    (response) => {
      const error = mapApiErrors(response);
      throw new Error(error);
    }
  );
};

export const update2Fa =
  (formData: EditTwoFactorFormType): AppThunk =>
  async (dispatch) => {
    return postItems<EditTwoFactorFormType, IApiResponse<boolean>>(ENDPOINTS.UPDATE_TWO_FACTOR, formData).then(
      (response) => {
        return response.result;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const selectServiceUserDetails = (state: RootState) => state.serviceUser.serviceUserDetails;

/* reducer */
export default serviceUserSlice.reducer;
