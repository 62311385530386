import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MainLayout from 'components/MainLayout/MainLayout';
import { fetchShubServiceCentre } from 'features/serviceCentre/serviceCentre.slice';
import {
  AuthProvider,
  ConfirmationServiceProvider,
  ErrorDialog,
  hideGenericErrorDialog,
  LoadingSpinner,
  NotAuthenticatedDialog,
  SavePromptProvider,
  selectShowGenericErrorDialog,
  selectShowNotAuthenticatedDialog,
  selectUiIsLoading,
  selectUserIsLoggedIn,
  THEME
} from 'millbrook-core';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'scss/App.scss';
import { AppRouter } from './AppRouter';

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden'
  }
}));

function App() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isLoading = useSelector(selectUiIsLoading);
  const isAuthenticationDialogShown = useSelector(selectShowNotAuthenticatedDialog);
  const genericErrorDialogMessage = useSelector(selectShowGenericErrorDialog);

  const userIsLoggedIn = useRef(useSelector(selectUserIsLoggedIn)).current;

  // load the service centre. This is used in lots of places, so easier to have it loaded and cached in the store
  // TODO: There is a bit of an issue where this is getting called more times than it should. userIsLoggedIn is causing the useEffect to run twice
  //       even though the boolean value is the same.
  //       hopefully a fix is to use the useRef on the selector.
  useEffect(() => {
    userIsLoggedIn && dispatch<any>(fetchShubServiceCentre());
  }, [dispatch, userIsLoggedIn]);

  return (
    <ThemeProvider theme={THEME}>
      <ConfirmationServiceProvider>
        <AuthProvider pageTitleDefault="Millbrook CARES">
          <SavePromptProvider>
            <CssBaseline />
            <LoadingSpinner isLoading={isLoading} />
            {isAuthenticationDialogShown && <NotAuthenticatedDialog />}
            {genericErrorDialogMessage && (
              <ErrorDialog
                open={!!genericErrorDialogMessage}
                message={genericErrorDialogMessage}
                onClose={() => dispatch(hideGenericErrorDialog())}
              />
            )}
            <div id="appContainer" className={classes.container}>
              <MainLayout>
                <AppRouter />
              </MainLayout>
            </div>
          </SavePromptProvider>
        </AuthProvider>
      </ConfirmationServiceProvider>
    </ThemeProvider>
  );
}

export default App;
