import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import clsx from 'clsx';
import { ROUTES } from 'constants/routes';
import {
  AUTH_ROUTES,
  BodyText,
  COLORS,
  HasAccess,
  Heading,
  MainMenuItem,
  StyledDrawer,
  StyledDrawerContent
} from 'millbrook-core';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const mainMenuDrawerStyles = makeStyles((theme) => ({
  drawerHeader: {
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 3)
    }
  },
  slidingMenu: {
    height: '100%',
    width: '100%',
    whiteSpace: 'nowrap',
    transition: 'all 0.5s',
    '& > *': {
      whiteSpace: 'normal'
    }
  },
  slidingMenuSub: {
    transform: 'translateX(-100%)'
  },
  drawerContentSlide: {
    height: '100%',
    overflow: 'auto',
    width: '100%',
    display: 'inline-block',
    verticalAlign: 'top'
  },
  drawerContentSlideMain: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 8, 2, 10)
    }
  },
  drawerContentSlideSub: {
    position: 'relative',
    padding: theme.spacing(2, 5, 2, 8),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 10)
    }
  },
  subMenuItem: {
    padding: theme.spacing(3, 2)
  },
  subMenuItemParent: {
    borderBottom: `1px solid ${COLORS.edward30}`,
    marginBottom: theme.spacing(2)
  },
  subMenuItemChild: {},
  subMenuClose: {
    position: 'absolute',
    transform: 'translateX(-100%) rotate(180deg)',
    left: 4,
    color: COLORS.black,
    [theme.breakpoints.up('sm')]: {
      left: -4
    }
  },
  mainMenuListItem: {
    marginBottom: theme.spacing(3),
    color: COLORS.regalBlue
  },
  menuItem: {
    '&:hover': {
      color: COLORS.scienceBlue,
      '& .MuiSvgIcon-root': { transform: `translateX(${theme.spacing(1)}px)`, opacity: 1 }
    },
    '& .MuiSvgIcon-root': {
      opacity: 0,
      transition: 'all 0.5s'
    }
  }
}));

const mainListArray: Omit<MainMenuItem, 'id'>[] = [
  {
    label: 'My dashboard',
    href: ROUTES.HOME
  },
  {
    label: 'My equipment',
    href: ROUTES.SERVICE_USER_EQUIPMENT
  },
  {
    label: 'My bookings',
    href: ROUTES.SERVICE_USER_BOOKINGS('')
  },
  {
    label: 'My details',
    href: ROUTES.SERVICE_USER_DETAILS
  },
  // MIL-10937 Remove "My feedback" from navigation until this is complete
  // {
  //   label: 'My feedback',
  //   href: ROUTES.SERVICE_USER_FEEDBACK
  // },
  {
    label: 'My service centre',
    href: ROUTES.SERVICE_CENTRE
  },
  {
    label: 'Log out',
    href: AUTH_ROUTES.LogOut
  }
];

interface MainMenuDrawerProps {
  open: boolean;
  onClose: () => void;
}

const MainMenuDrawer = ({ open, onClose }: MainMenuDrawerProps) => {
  const classes = mainMenuDrawerStyles();
  const [showSub, setShowSub] = useState(false);
  const [subMenuData, setSubMenuData] = useState<MainMenuItem>();

  const closeSubMenu = () => {
    setShowSub(false);
    setTimeout(() => setSubMenuData(undefined), 600);
  };
  const handleMenuClick = (e: React.MouseEvent, menuItem: MainMenuItem) => {
    if (menuItem.subMenu) {
      e.preventDefault();
      setSubMenuData(menuItem);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    setShowSub(subMenuData ? true : false);
  }, [subMenuData]);

  return (
    <StyledDrawer open={open} onClose={onClose}>
      <StyledDrawerContent>
        <Box className={clsx(classes.slidingMenu, showSub && classes.slidingMenuSub)}>
          <Box className={clsx(classes.drawerContentSlide, classes.drawerContentSlideMain)}>
            <List>
              {mainListArray.map((listItem) => {
                return (
                  <HasAccess
                    key={listItem.label}
                    permission={listItem.permissions}
                    yes={
                      <ListItem
                        button
                        className={clsx(classes.menuItem, classes.mainMenuListItem)}
                        component={RouterLink}
                        to={listItem.href}
                        onClick={(e: React.MouseEvent) => handleMenuClick(e, listItem)}
                      >
                        <Heading component="span" type="h3" color="inherit">
                          {listItem.label}
                        </Heading>
                        <ArrowRightAltIcon fontSize="large" />
                      </ListItem>
                    }
                  />
                );
              })}
            </List>
          </Box>
          {subMenuData && (
            <Box className={clsx(classes.drawerContentSlide, classes.drawerContentSlideSub)}>
              <List>
                <ListItem button onClick={closeSubMenu}>
                  <IconButton aria-label="back to menu" className={classes.subMenuClose} onClick={closeSubMenu}>
                    <ArrowRightAltIcon fontSize="large" />
                  </IconButton>
                  <Heading component="div" type="h3">
                    Back to menu
                  </Heading>
                </ListItem>
                {/* This is the top item that is the parent link of the sub-section */}
                <ListItem
                  className={clsx(classes.menuItem, classes.subMenuItem, classes.subMenuItemParent)}
                  button
                  component={RouterLink}
                  to={subMenuData.href}
                >
                  <BodyText color="inherit">{subMenuData.label}</BodyText>
                  <ArrowRightAltIcon />
                </ListItem>
                {/* The following items are the sub category children */}
                {subMenuData.subMenu?.map((item) => (
                  <ListItem
                    key={item.label}
                    className={clsx(classes.menuItem, classes.subMenuItem, classes.subMenuItemChild)}
                    button
                    component={RouterLink}
                    to={item.href}
                    onClick={onClose}
                  >
                    <BodyText color="inherit">{item.label}</BodyText>
                    <ArrowRightAltIcon />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Box>
      </StyledDrawerContent>
    </StyledDrawer>
  );
};

export default MainMenuDrawer;
