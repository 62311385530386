import { ActivityDetailsModel, ActivityStatus, ApiId, IGuidName, IsoDate } from 'millbrook-core';

export interface ServiceUserActivityModel {
  id: ApiId;
  activityStatus: ActivityStatus;

  dateCreated: IsoDate;
  TargetDate: IsoDate;
  activityReference: string;
  items: IGuidName[];
  alerts: IGuidName[];
}

// this contains data derived from the base model
export interface ServiceUserActivityStatusModel extends ServiceUserActivityModel {
  activitySimpleStatus: ActivitySimpleStatusEnum;
  activitySimpleType: string;
}

export interface ServiceUserActivityDetailModel extends ActivityDetailsModel {
  alerts: IGuidName[];
  activitySimpleStatus: ActivitySimpleStatusEnum;
}

// enums

export enum ActivitySimpleStatusEnum {
  Open,
  Closed,
  Cancelled,
  Failed
}
