import { ApiId, GLOBAL_ENDPOINTS } from 'millbrook-core';

// Note: see examples of setup in contracts or portal api.ts
export const ENDPOINTS = {
  ...GLOBAL_ENDPOINTS,
  SERVICE_CENTRE: 'ServiceHub/serviceCentre',
  SERVICE_USER_DETAILS: 'ServiceHub/serviceUserDetails',
  SERVICE_USER_BOOKINGS: (upcomingOnly?: boolean) => `ServiceHub/Activities${upcomingOnly ? '?upcomingOnly=true' : ''}`,
  UPDATE_TWO_FACTOR: 'ServiceHub/UpdateTwoFactor',
  TWO_FACTOR_MEDIUMS: 'ServiceHub/TwoFactorMediums',
  EOS: {
    GET_EOS_LIST: `ServiceHub/EosList`,
    GET_EOS_DETAIL: (productId: ApiId, serviceUserId: ApiId) => `ServiceHub/eosdetail/${productId}/${serviceUserId}`,
    CREATE_REPAIR: 'ServiceHub/CreateRepair',
    CREATE_RETURN: 'ServiceHub/CreateReturn'
  }
};
