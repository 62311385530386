import {
  ApiAuthorizationRoutes,
  AUTH_ROUTES,
  LoadingSpinner,
  NotAuthorisedPage,
  ProtectedRoute,
  selectUserIsLoggedIn
} from 'millbrook-core';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../constants';

const HomePage = lazy(() => import('pages/HomePage/HomePage'));
const DashboardPage = lazy(() => import('pages/DashboardPage/DashboardPage'));
const ShubUserDetailsPage = lazy(() => import('pages/ShubUserDetailsPage/ShubUserDetailsPage'));
const ShubActivitiesPage = lazy(() => import('pages/ShubActivitiesPage/ShubActivitiesPage'));
const ShubActivityDetailsPage = lazy(() => import('pages/ShubActivityDetailsPage/ShubActivityDetailsPage'));
const ShubEquipmentPage = lazy(() => import('pages/ShubEquipmentPage/ShubEquipmentPage'));
const ShubEquipmentDetailsPage = lazy(() => import('pages/ShubEquipmentDetailsPage/ShubEquipmentDetailsPage'));
const ShubFeedbackPage = lazy(() => import('pages/ShubFeedbackPage/ShubFeedbackPage'));
const ShubServiceCentrePage = lazy(() => import('pages/ShubServiceCentrePage/ShubServiceCentrePage'));

const AppRouter = () => {
  const userIsLoggedIn = useSelector(selectUserIsLoggedIn);

  return (
    <Suspense fallback={<LoadingSpinner isLoading />}>
      <Switch>
        <Route path={ROUTES.HOME} exact component={userIsLoggedIn ? DashboardPage : HomePage} />

        <Route path={AUTH_ROUTES.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />

        <ProtectedRoute path={ROUTES.SERVICE_USER_EQUIPMENT} exact component={ShubEquipmentPage} />
        <ProtectedRoute path={ROUTES.SERVICE_USER_EQUIPMENT_DETAILS()} exact component={ShubEquipmentDetailsPage} />
        <ProtectedRoute path={ROUTES.SERVICE_USER_BOOKINGS('')} exact component={ShubActivitiesPage} />
        <ProtectedRoute path={ROUTES.SERVICE_USER_BOOKINGS()} exact component={ShubActivityDetailsPage} />
        <ProtectedRoute path={ROUTES.SERVICE_USER_DETAILS} exact component={ShubUserDetailsPage} />
        <ProtectedRoute path={ROUTES.SERVICE_USER_FEEDBACK} exact component={ShubFeedbackPage} />
        <ProtectedRoute path={ROUTES.SERVICE_CENTRE} exact component={ShubServiceCentrePage} />

        <Route path={ROUTES.NOT_AUTHORISED} render={() => <NotAuthorisedPage url={ROUTES.HOME} />} />
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
};

export { AppRouter };
