// using a thunk only, doesn't need to go to the store at this point

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { IApiResponse, IServiceCentre } from 'millbrook-core';
import { getItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';

interface ServiceCentreState {
  serviceCentre?: IServiceCentre;
}

const initialState: ServiceCentreState = {};

const serviceCentreSlice = createSlice({
  name: 'serviceCentre',
  initialState,
  reducers: {
    setServiceCentre(state, action: PayloadAction<IServiceCentre | undefined>) {
      state.serviceCentre = action.payload;
    }
  }
});

/**
 * thunks
 */

export const fetchShubServiceCentre = (): AppThunk => async (dispatch) => {
  return getItem<string, IApiResponse<IServiceCentre>>(ENDPOINTS.SERVICE_CENTRE, '', {
    cacheName: 'service-centre',
    disableFullPageLoader: true
  }).then(
    (response) => {
      dispatch(serviceCentreSlice.actions.setServiceCentre(response.result));
    },
    (response) => {
      //const error = mapApiErrors(response);
      //throw new Error(error);
      // This will be handled in the relevant sections that use the service centre
    }
  );
};

/**
 * selectors
 */

export const selectServiceCentre = (state: RootState) => state.serviceCentre.serviceCentre;

export default serviceCentreSlice.reducer;
