import { combineReducers, PayloadAction } from '@reduxjs/toolkit';
import activity from 'features/activity/activity.slice';
import serviceCentre from 'features/serviceCentre/serviceCentre.slice';
import serviceUser from 'features/serviceUser/serviceUser.slice';
import { baseRootReducers } from 'millbrook-core';

/* reducers */
const allReducers = combineReducers({
  ...baseRootReducers,
  serviceCentre,
  activity,
  serviceUser
});

// TODO: work out the state here
const createRootReducer = (state: any, action: PayloadAction<any>) => {
  if (action.type === 'hydrate') {
    state = action.payload;
  }
  return allReducers(state, action);
};

export const rootReducer = createRootReducer;
