import { ApiId, GLOBAL_ROUTES } from 'millbrook-core';
import { ROUTEPARAMS } from './routeParameters';

export const ROUTES = {
  ...GLOBAL_ROUTES,
  SERVICE_USER_DETAILS: `/my-details`,
  SERVICE_CENTRE: `/my-service-centre`,
  SERVICE_USER_EQUIPMENT: `/my-equipment`,
  SERVICE_USER_FEEDBACK: `/my-feedback`,
  SERVICE_USER_BOOKINGS: (activityId: ApiId = ROUTEPARAMS.ACTIVITY_ID) => `/my-bookings/${activityId}`,
  SERVICE_USER_EQUIPMENT_DETAILS: (
    productId: ApiId = ROUTEPARAMS.PRODUCT_ID,
    serviceUserId: ApiId = ROUTEPARAMS.SERVICEUSER_ID
  ) => `/my-equipment/${productId}/${serviceUserId}`
};
